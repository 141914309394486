<template>
  <div class="communication-settings">
    <setting-item
      name="WhatsApp Web"
      description="Envio de mensagens de agendamento para o paciente utilizando o WhatsApp Web"
      type="switch"
      :value="form.whatsappWeb.enabled"
      :action-icon="['fal', 'cog']"
      :action-fn="() => { whatsappWebModal.show = true }"
      :action-disabled="form.whatsappWeb.enabled === false"
      @change="updateValue('enabled', $event, 'whatsappWeb')"
    ></setting-item>

    <setting-item
      name="Integração WhatsApp"
      description="Uma ferramenta ideal para otimizar e automatizar o processo de envio
        de mensagens aos pacientes"
      info="Trata-se de um serviço adicional, e possui custo por mensagem enviada.
        Para mais informações, contate nosso suporte."
      type="switch"
      :value="form.whatsapp.enabled"
      :action-icon="['fal', 'cog']"
      :action-fn="() => { whatsappModal.show = true }"
      :action-disabled="form.whatsapp.enabled === false"
      @change="updateValue('enabled', $event, 'whatsapp')"
    ></setting-item>

    <setting-item
      name="Email"
      description="Uma solução eficiente para automatizar o envio de comunicação com os pacientes"
      info="Trata-se de um serviço adicional, e possui custo por mensagem enviada.
        Para mais informações, contate nosso suporte."
      type="switch"
      :value="form.email.enabled"
      @change="updateValue('enabled', $event, 'email')"
    ></setting-item>

    <setting-item
      v-for="item in templateItems"
      :key="item.key"
      :name="item.name"
      :description="item.description"
      type="select"
      :options="messageOptions"
      :action-icon="['fal', 'cog']"
      :action-fn="openTemplate(item)"
      :action-disabled="form.communication[item.key] === 'none'"
      :soon="item.soon"
      :value="form.communication[item.key]"
      @change="updateValue(item.key, $event, 'communication')"
    ></setting-item>

    <setting-item
      name="Pesquisa de satisfação"
      description="Mensagem para o paciente convidando-o a participar de uma pesquisa"
      type="select"
      :options="messageOptions"
      :action-icon="['fal', 'cog']"
      :action-fn="() => {}"
      :action-disabled="form.communication.satisfactionSurvey === 'none'"
      :soon="true"
      :value="form.communication.satisfactionSurvey"
      @change="updateValue('satisfactionSurvey', $event, 'communication')"
    ></setting-item>

    <dx-modal v-model="whatsappWebModal.show" title="Mensagens - WhatsApp Web">
      <st-tabs>
        <st-tab name="Confirmação de agendamento">
          <div class="form-group pt-2">
            <textarea
              class="form-input"
              rows="8"
              v-model="form.whatsappWeb.appointmentConfirmationTemplate"
              placeholder="Conteúdo do modelo..."
              v-focus
            ></textarea>
          </div>
          <button
            class="btn btn-sm btn-info-outline"
            @click="form.whatsappWeb.appointmentConfirmationTemplate
            = templates.WHATSAPP_WEB_APPOINTMENT_CONFIRMATION"
          >Usar modelo padrão</button>
        </st-tab>
        <st-tab name="Lembrete de agendamento">
          <div class="form-group pt-2">
            <textarea
              class="form-input"
              rows="8"
              v-model="form.whatsappWeb.appointmentReminderTemplate"
              placeholder="Conteúdo do modelo..."
              v-focus
            ></textarea>
          </div>
          <button
            class="btn btn-sm btn-info-outline"
            @click="form.whatsappWeb.appointmentReminderTemplate
            = templates.WHATSAPP_APPOINTMENT_REMINDER"
          >Usar modelo padrão</button>
        </st-tab>
      </st-tabs>
      <template v-slot:footer>
        <button
          class="btn btn-primary"
          :class="{ loading: saving }"
          :disabled="saving"
          @click="saveWhatsappWebTemplates"
        >Salvar</button>
        <button class="btn ml-2" @click="whatsappWebModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <dx-modal v-model="whatsappModal.show" title="Mensagens - WhatsApp">
      <whatsapp-options
        v-if="whatsappModal.show"
        :params="form.whatsapp"
        v-model="form.whatsapp.variables"
      ></whatsapp-options>

      <template v-slot:footer>
        <button
          class="btn btn-info-outline float-left"
          @click="preview(null)"
        >Pré-visualizar</button>
        <button
          class="btn btn-primary"
          :class="{ loading: saving }"
          :disabled="saving"
          @click="saveWhatsappVariables"
        >Salvar</button>
        <button class="btn ml-2" @click="whatsappModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <dx-modal
      :value="templateModal.show"
      @input="closeTemplate"
      :title="templateModalTitle"
    >
      <div class="form-group">
        <label for="template-id" class="form-label">Modelo</label>
        <div class="input-group">
          <select id="template-id" class="form-select" v-model="templateModal.value">
            <option :value="null">[Selecione]</option>
            <option
              v-for="item in templateModal.items"
              :value="item.id"
              :key="item.id"
            >{{ item.text }}</option>
          </select>
          <button
            class="btn btn-icon btn-action btn-primary input-group-btn"
            title="Visualizar"
            @click="preview(templateModal.value)"
            :disabled="!templateModal.value"
          ><fa-icon :icon="['fal', 'eye']" /></button>
        </div>
      </div>

      <div class="form-group" v-if="templateModal.groupedField">
        <label for="template-id" class="form-label">Modelo agrupado</label>
        <div class="input-group">
          <select id="template-id" class="form-select" v-model="templateModal.groupedValue">
            <option :value="null">Não agrupar</option>
            <option
              v-for="item in templateModal.groupedItems"
              :value="item.id"
              :key="item.id"
            >{{ item.text }}</option>
          </select>
          <button
            class="btn btn-icon btn-action btn-primary input-group-btn"
            title="Visualizar"
            @click="preview(templateModal.groupedValue)"
            :disabled="!templateModal.groupedValue"
          ><fa-icon :icon="['fal', 'eye']" /></button>
        </div>
      </div>

      <template v-slot:footer>
        <router-link
          class="btn btn-info-outline float-left"
          to="/message-templates"
        >Gerenciar modelos</router-link>
        <button
          class="btn btn-primary"
          :class="{ loading: saving }"
          :disabled="saving"
          @click="saveTemplate"
        >Salvar</button>
        <button class="btn ml-2" @click="closeTemplate">Sair</button>
      </template>
    </dx-modal>

    <message-preview-modal
      v-model="previewModal.show"
      :template-id="previewModal.templateId"
    />
  </div>
</template>

<script>
import { mergeFrom } from '@/helpers/object';
import * as templates from '@/data/message-templates';
import * as types from '@/data/message-types';
import MessagePreviewModal from '@/components/message/PreviewModal.vue';
import SettingItem from '../SettingItem.vue';
import WhatsappOptions from './WhatsappOptions.vue';

const templateItems = [
  {
    key: 'appointmentCreation',
    type: types.APPOINTMENT_CREATION,
    name: 'Agendamento criado',
    description: 'Mensagem para o paciente quando um agendamento é criado',
  },
  {
    key: 'appointmentConfirmation',
    type: types.APPOINTMENT_CONFIRMATION,
    groupedType: types.APPOINTMENT_CONFIRMATION_GROUPED,
    name: 'Confirmação de agendamento',
    description: 'Mensagem para o paciente confirmar ou cancelar o agendamento',
  },
  {
    key: 'appointmentReminder',
    type: types.APPOINTMENT_REMINDER,
    groupedType: types.APPOINTMENT_REMINDER_GROUPED,
    name: 'Lembrete de agendamento',
    description: 'Mensagem para o paciente lembrando do agendamento',
  },
  {
    key: 'birthday',
    type: types.BIRTHDAY,
    name: 'Aniversário',
    description: 'Mensagem para celebrar o aniversário do paciente',
    soon: true,
  },
];

export default {
  props: {
    loading: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    SettingItem,
    WhatsappOptions,
    MessagePreviewModal,
  },
  data() {
    return {
      saving: false,
      form: this.blankForm(),
      whatsappWebModal: {
        show: false,
      },
      whatsappModal: {
        show: false,
      },
      previewModal: {
        show: false,
        templateId: null,
      },
      templateModal: this.blankTemplateModal(),
      templateItems,
      templates,
    };
  },
  watch: {
    loading() {
      this.updateForm();
    },
  },
  computed: {
    messageOptions() {
      const items = [{ value: 'none', text: 'Não enviar' }];

      if (this.form.whatsapp.enabled) {
        items.push({ value: 'whatsapp', text: 'Whatsapp' });
      }
      if (this.form.email && this.form.email.enabled) {
        items.push({ value: 'email', text: 'Email' });
      }

      return items;
    },
    templateModalTitle() {
      if (!this.templateModal.show) return '';
      const item = templateItems.find(({ key }) => key === this.templateModal.key);
      return `Mensagem - ${item.name}`;
    },
  },
  methods: {
    updateForm() {
      this.form = mergeFrom(this.form, this.settings);
    },
    async saveWhatsappWebTemplates() {
      await this.update({
        whatsappWeb: this.form.whatsappWeb,
      });
      this.whatsappWebModal.show = false;
    },
    async saveWhatsappVariables() {
      await this.update({
        whatsapp: this.form.whatsapp,
      });
      this.whatsappModal.show = false;
    },
    openTemplate(templateData) {
      const { key, type, groupedType = null } = templateData;

      return async () => {
        const formData = this.form.communication;

        this.templateModal.key = key;
        this.templateModal.channel = formData[key];
        this.templateModal.type = type;
        this.templateModal.field = `${key}TemplateId`;
        this.templateModal.value = formData[this.templateModal.field] || null;
        if (groupedType) {
          this.templateModal.groupedField = `${key}GroupedTemplateId`;
          this.templateModal.groupedValue = formData[this.templateModal.groupedField] || null;
        }

        try {
          const params = {
            type: [type, ...(groupedType ? [groupedType] : [])].join(','),
            channel: this.templateModal.channel,
            status: 'approved',
          };

          const { data } = await this.$http.get('/message-templates', { params });
          data.items = data.items.map((item) => {
            item.text = `${types.getName(item.type)} (${item.label})`;
            return item;
          });

          this.templateModal.items = data.items.filter(item => item.type === type);
          if (groupedType) {
            this.templateModal.groupedItems = data.items.filter(item => item.type === groupedType);
          }
          this.templateModal.show = true;
        } catch (e) {
          this.$toast.error(e);
        }
      };
    },
    closeTemplate() {
      this.templateModal = this.blankTemplateModal();
    },
    async saveTemplate() {
      const inputs = [{
        field: this.templateModal.field,
        value: this.templateModal.value,
      }];
      if (this.templateModal.groupedField) {
        inputs.push({
          field: this.templateModal.groupedField,
          value: this.templateModal.groupedValue,
        });
      }

      const updateData = { communication: {} };
      inputs.forEach(({ field, value }) => {
        updateData.communication[field] = value;
      });

      await this.update(updateData);

      inputs.forEach(({ field, value }) => {
        this.form.communication[field] = value;
      });

      this.closeTemplate();
    },
    async updateValue(key, value, group) {
      await this.update({
        [group]: {
          [key]: value,
        },
      });
      this.form[group][key] = value;
    },
    async update(data = null) {
      this.saving = true;
      try {
        await this.$http.put('/facility-settings', data || this.form);
        this.$toast.success('Configuração salva com sucesso');
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    preview(templateId) {
      this.previewModal.show = true;
      this.previewModal.templateId = templateId;
    },
    blankForm() {
      return {
        whatsappWeb: {
          enabled: true,
          appointmentConfirmationTemplate: '',
          appointmentReminderTemplate: '',
        },
        whatsapp: {
          enabled: false,
          adapter: 'meta',
          params: {},
          dedicated: false,
          variables: {},
        },
        email: {
          enabled: false,
        },
        communication: {
          appointmentCreation: 'none',
          appointmentCreationTemplateId: null,
          appointmentConfirmation: 'none',
          appointmentConfirmationTemplateId: null,
          appointmentConfirmationGroupedTemplateId: null,
          appointmentReminder: 'none',
          appointmentReminderTemplateId: null,
          appointmentReminderGroupedTemplateId: null,
          birthday: 'none',
          birthdayTemplate: '',
          birthdayTemplateId: null,
          satisfactionSurvey: 'none',
          satisfactionSurveyItems: [],
        },
      };
    },
    blankTemplateModal() {
      return {
        show: false,
        key: null,
        channel: null,
        type: null,
        field: null,
        value: null,
        items: [],
        groupedField: null,
        groupedValue: null,
        groupedItems: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/_variables.scss";

.communication-settings {
  .setting-item:first-child {
    border-top: 0;
  }
}
</style>
