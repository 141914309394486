<template>
  <dx-modal
    :value="value"
    @input="$emit('input', $event)"
    title="Pré-visualização de mensagem"
    size="sm"
  >
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="form-group">
        <select id="template-id" class="form-select" v-model="selectedTemplateId">
          <option
            v-for="item in templates"
            :value="item.id"
            :key="item.id"
          >{{ item.text }}</option>
        </select>
      </div>

      <message-preview
        v-if="template"
        :channel="template.channel"
        :header="template.header"
        :body="template.body"
        :footer="template.footer"
        :buttons="template.buttons"
        :variables="variables"
      />
    </template>
  </dx-modal>
</template>

<script>
import * as types from '@/data/message-types';
import MessagePreview from './Preview.vue';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: String,
    },
  },
  components: {
    MessagePreview,
  },
  data() {
    return {
      initiated: false,
      loading: false,
      selectedTemplateId: '',
      templates: [],
      variables: {},
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.init();
      }
    },
    templateId() {
      this.selectedTemplateId = this.templateId;
    },
  },
  computed: {
    template() {
      if (this.initiated && this.selectedTemplateId) {
        return this.templates.find(({ id }) => id === this.selectedTemplateId);
      }
      return null;
    },
  },
  methods: {
    async init() {
      if (!this.initiated) {
        this.loading = true;

        await Promise.all([
          this.loadTemplates(),
          this.loadVariables(),
        ]);

        if (!this.selectedTemplateId) {
          this.selectedTemplateId = this.templates[0].id;
        }

        this.initiated = true;
        this.loading = false;
      }
    },
    async loadTemplates() {
      try {
        const params = {
          channel: 'whatsapp',
          status: 'approved',
        };

        const { data } = await this.$http.get('/message-templates', { params });
        this.templates = data.items.map((item) => {
          item.text = `${types.getName(item.type)} (${item.label})`;
          return item;
        });
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadVariables() {
      try {
        const { data } = await this.$http.get('/message-variables');
        this.variables = data;
      } catch (e) {
        this.$toast.error(e);
      }
    },
  },
};
</script>
