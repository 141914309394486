<template>
  <div class="message-preview">
    <div
      v-if="header"
      class="message-header"
    >{{ header }}</div>
    <div
      v-if="body"
      class="message-body"
      v-html="htmlBody"
    />
    <div class="message-buttons" v-if="buttons.length > 0">
      <button
        class="btn btn-block btn-icon btn-icon-left"
        v-for="(button, i) in buttons"
        :key="i"
      ><fa-icon :icon="['fal', 'reply']" /> {{ button }}</button>
    </div>
    <div
      v-if="footer"
      class="message-footer"
    >{{ footer }}</div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: String,
      enum: ['whatsapp', 'email'],
    },
    header: {
      type: String,
    },
    body: {
      type: String,
    },
    footer: {
      type: String,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    variables: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    htmlBody() {
      if (!this.body) return '';

      const content = this.renderVariables(this.body);

      if (this.channel === 'whatsapp') {
        return this.whatsappToHTML(content);
      }
      if (this.channel === 'markdown') {
        return content;
      }

      return content;
    },
  },
  methods: {
    renderVariables(value) {
      return Object.keys(this.variables)
        .reduce((acc, key) => (
          acc.replace(new RegExp(`{{${key}}}`, 'ig'), this.variables[key])
        ), value);
    },
    whatsappToHTML(text) {
      return text
        .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        .replace(/~(.*?)~/g, '<s>$1</s>')
        .replace(/\n/g, '<br/>');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.message-preview {
  background-color: #D9FDD3;
  border: $border-width solid $graphic-color-green-2;
  border-radius: $layout-spacing;
  padding: $layout-spacing-lg;

  .message-header {
    font-weight: 600;
    font-size: $font-size-lg;
    margin-bottom: $layout-spacing-lg;
  }
  .message-body {
    strong {
      font-weight: 600;
    }
  }
  .message-buttons {
    margin-top: $layout-spacing-lg;
    .btn {
      background-color: transparent;
      border-color: $graphic-color-green-2;
      color: $green-color-800;
      margin-top: $layout-spacing;
      svg {
        margin-right: $layout-spacing;
      }
    }
  }
  .message-footer {
    color: $gray-color-dark;
    margin-top: $layout-spacing-lg;
  }
}
</style>
