<template>
  <div class="whatsapp-options">
    <div class="loading loading-lg" v-if="loading"></div>
    <template v-else>
      <div class="columns">
        <div class="column col-7 form-group">
          <small>Tipo de integração</small>
          <div
            class="text-bold text-secondary"
          >Número {{ isDedicated ? 'dedicado' : 'Stenci'}}</div>
        </div>
        <div class="column col-5 form-group">
          <small>Status</small>
          <div class="text-bold status" :class="`status-${info.status}`">
            {{ statusLabel }}
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column col-7 form-group">
          <small>Conta conectada</small>
          <div
            class="text-bold text-secondary"
          >{{ info.number | phone }} - {{ info.name }}</div>
        </div>
        <div class="column col-5 form-group">
          <small>Qualidade</small>
          <div class="text-bold quality" :class="`quality-${info.quality}`">
            {{ qualityLabel }}
          </div>
        </div>
      </div>

      <div class="form-horizontal customizable-variables">
        <div class="text-bold text-info">Customizações</div>
        <div class="divider" />

        <div class="form-group" v-for="(varData, key) in customizableVariables" :key="key">
          <div class="col-3 col-sm-12">
            <label class="form-label" :for="`var-${key}`">{{ varData.label }}</label>
          </div>
          <div class="col-9 col-sm-12">
            <input
              class="form-input"
              type="text"
              :id="`var-${key}`"
              v-model="varData.value"
              @change="updateModel"
              :placeholder="varData.placeholder"
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mergeFrom } from '@/helpers/object';

export default {
  props: {
    value: {
      type: Object,
    },
    params: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      info: this.blankInfo(),
      customizableVariables: {
        facility_preposition: {
          label: 'Preposição',
          placeholder: 'do(a)',
          value: '',
        },
        facility_name: {
          label: 'Nome',
          placeholder: 'Nome',
          value: '',
        },
        facility_whatsapp: {
          label: 'Whatsapp',
          placeholder: '(00) 0000-0000',
          value: '',
        },
        facility_phone: {
          label: 'Telefone',
          placeholder: '(00) 0000-0000',
          value: '',
        },
        facility_cellphone: {
          label: 'Celular',
          placeholder: '(00) 0000-0000',
          value: '',
        },
        facility_email: {
          label: 'Email',
          placeholder: 'email@email.com',
          value: '',
        },
        facility_address: {
          label: 'Endereço',
          placeholder: 'Avenida Brasil, 2025',
          value: '',
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isDedicated() {
      return this.params.dedicated;
    },
    statusLabel() {
      switch (this.info.status) {
        case 'connected': return 'Conectado';
        case 'pending': return 'Pendente';
        case 'offline': return 'Offline';
        case 'flagged': return 'Baixa qualidade';
        case 'restricted': return 'Restrito';
        default: return 'Desconhecido';
      }
    },
    qualityLabel() {
      switch (this.info.quality) {
        case 'green': return 'Alta';
        case 'yellow': return 'Média';
        case 'red': return 'Baixa';
        default: return 'Desconhecida';
      }
    },
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all([
        this.load(),
        this.loadVariables(),
      ]);
      this.loading = false;
    },
    async load() {
      try {
        const { data } = await this.$http.get('/facility-settings/whatsapp');
        this.info = mergeFrom(this.blankInfo(), data);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadVariables() {
      try {
        const { data } = await this.$http.get('/message-variables');
        Object.keys(this.customizableVariables).forEach((key) => {
          this.customizableVariables[key].value = key in data ? data[key] : '';
        });
      } catch (e) {
        this.$toast.error(e);
      }
    },
    updateModel() {
      const data = Object.keys(this.customizableVariables).reduce((acc, key) => {
        acc[key] = this.customizableVariables[key].value.trim();
        return acc;
      }, {});
      this.$emit('input', data);
    },
    blankInfo() {
      return {
        status: '',
        number: '',
        name: '',
        quality: '',
        verificationStatus: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.whatsapp-options {
  .status, .quality {
    color: $gray-color-dark;
  }

  .status-connected,
  .quality-green {
    color: $success-color;
  }

  .status-pending,
  .quality-yellow {
    color: $warning-color;
  }

  .status-offline,
  .status-flagged,
  .status-restricted,
  .quality-red {
    color: $error-color;
  }

  .customizable-variables {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
    margin-top: $layout-spacing;
    .divider {
      border-top-color: $border-color;
      margin-bottom: $layout-spacing-lg;
    }
  }
}
</style>
